@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap);
/* Hitesh code full responsive query --line 2089 */
@font-face {
  font-family: "GalanoGrotesque";
  src: local("GalanoGrotesque"),
    url(/static/media/GalanoGrotesqueRegular.0db105f8.otf) format("truetype");
}

.active-btn{
  color: white !important;
  background-color: #e5007d !important;
}

.accordion-button:not(.collapsed):after{
  display: none;
}

.active-btn:hover{
  color: white !important;
}

body {

  margin: 0 !important;
  padding: 0 !important;
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

* {
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Free") !important;
  font-weight: var(--fa-style, 900) !important;
  font: normal normal normal 14px/1 FontAwesome !important;
}

.displayNone {
  display: none !important;
  text-align: center;
}

iframe {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input[type="number"] {
  moz-appearance: textfield;
}

h4 {
  font-weight: 600 !important;
}


.tour-info-modal {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  background-color: white;
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border: 1px solid #e5007d;
  border-radius: 8px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.256);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}
.popup-content {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: 100%;
  max-height: 90vh;
  overflow-y: scroll;
  
}



.td {
  font-weight: 400 !important;
}

button {
  font-family: "GalanoGrotesque";
  cursor: pointer !important;
}

.table> :not(caption)>*>* {
  text-wrap: nowrap;
  padding: 0.3rem 0.6rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.MuiToolbar-gutters{
  padding-left: 15px !important;
}

/* =======================================loder=================================== */
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgb(255, 255, 255);
  z-index: 1;
}

.loader-containersss {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
}

.spinner {
  position: absolute;
  width: 200px;
  height: 200px;
  border: 15px solid;
  border-color: #e5007d transparent #e5007d transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

.spinnerss {
  position: absolute;
  width: 50px;
  height: 50px;
  border: 3px solid;
  border-color: #e5007d transparent #e5007d transparent;
  border-radius: 50%;
  animation: spin-anim 1s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }

}

/* ================================alert-triangle================================ */

.alert-triangle {
  position: relative;
  top: 32px;
  right: 152px !important;
}

.section {
  width: 100%;
  height: 100vh;
}

.sectionlanding {
  width: 100% !important;
  height: auto !important;
  background: "#F8F8F8";
}

.mainimg {
  width: 100%;
  height: 100%;
}

.contener {
  width: 100%;
  height: 100%;
  display: block;
}

.errorshows {
  padding-bottom: 0%;
}

.Row {
  width: 100%;
  height: auto;
  display: flex;
}

.col1 {
  width: 50%;
  height: 100vh;

  @media (max-width: 1200px) {
    display: none;
  }
}

.col {
  width: 50%;
  height: 100vh;
  background-color: #fff;
  text-align: center;
  display: block;
}

.homecontener {
  width: 50%;
  height: 80%;
  margin: auto;
  text-align: center;
}

.logo {
  padding-bottom: 4%;
  width: 211;
  height: 140;
}

.logo1 {
  padding-bottom: 4%;
  width: 211;
  height: 140;
}

.dateinputs {
  width: 100%;
  height: 49px;
  padding: 2%;
}

.lableintext {
  text-align: initial;
  margin: 3px 0px;
}

.hading {
  font-family: "GalanoGrotesque";
  font-weight: 700;
  font-size: 28px !important;
  line-height: 130%;
  width: 85%;
  word-spacing: 6px;
  height: 100px;
  margin: auto;
  display: grid;
  align-items: center;
  color: #251f2b;
}

.welhading {
  font-family: "GalanoGrotesque";
  font-weight: 700;
  font-size: 1.75rem !important;
  width: 98%;
  word-spacing: 6px;
  height: 100px;
  margin: auto;
  display: grid;
  align-items: center;
}

.rghading {
  font-family: "GalanoGrotesque";
  font-weight: 700;
  font-size: 1.75rem !important;
  width: 98%;
  word-spacing: 6px;
  height: 50px;
  margin: auto;
  display: grid;
  align-items: center;
}

p {
  font-family: "GalanoGrotesque";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem !important;
  line-height: 150%;
  width: 327px;
  text-align: center;
  color: #757575;
  margin: auto;
  padding-bottom: 15%;
}

small {
  text-align: justify;
  margin: 10px 5px;
  color: red;
}

.button {
  font-family: "GalanoGrotesque";
  width: 100%;
  height: 56px;
  background: #e5007d;
  display: grid;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  border: none;
  text-decoration: none;
  align-content: space-around;
  border-radius: 6px;
  box-shadow: 0px 3px 5px -2px rgba(192, 31, 119, 0.3);
  border-radius: 6px;
  display: inline-grid;
}

.inputfild {
  margin: 20px;
}

text {
  font-family: "GalanoGrotesque";
  width: 100%;
  font-weight: 400;
  font-size: 0.875rem !important;
  line-height: 24px;
  color: #757575;
}

.player {
  font-family: "GalanoGrotesque";
  font-weight: 400;
  font-size: 0.875rem !important;
  line-height: 24px;
  color: #757575;
}

/* span {
  font-family: "GalanoGrotesque";
  font-weight: 600;
  font-size: 0.875rem !important;
  line-height: 24px;
  color: #e5007d;
  cursor: pointer;
  margin-left: 5px;
} */
.footer {
  padding-top: 20px;
}

.d-box {
  width: 50%;
  height: 100%;
  margin: auto;
  display: grid;
  justify-items: center;
  align-content: center;
}

.d-box3 {
  width: 40%;
  height: 100%;
  margin: auto;
  display: grid;
  justify-items: center;
  align-content: center;
}

.d-box1 {
  width: 40%;
  height: 100%;
  margin: 15% auto;
  display: grid;
  justify-items: center;
  align-content: center;
}

.footer-links {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.password {
  font-family: "GalanoGrotesque";
  color: #e5007d;
  text-decoration: none;
  font-size: 14px;
}

.otpContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 24px 0px;
}

.otpInput {
  width: 64px;
  height: 56px;
  border-radius: 6px;
  border: 1px solid #c2c2c2;
  text-align: center;
  font-family: "GalanoGrotesque";
  font-size: 24px;
  font-weight: bold;
}

.floating-labelss {
  position: absolute;
  pointer-events: none;
  left: 10px;
  font-size: 10px !important;
  color: #757575;
}

/* ============================================ admin screen =============================== */
.adminbutton {
  width: 145px !important;
  height: 40px;
  position: relative;
  right: 2%;
  border: none;
  border-radius: 8px;
  background-color: #C2C2C2;
  color: #757575;
  font-weight: bold;
}

.adminbuttons {
  display: grid;
  justify-content: end;
  padding: 0% 2%;
}

.leagunamedata {
  padding-left: 2% !important;
}

.createbydata {
  padding-right: 3% !important;
}

tr:nth-child(even) {
  background-color: #D6EEEE;
}

.landingsscreens {
  width: 100% !important;
  /* height: 100vh !important; */
}

.adminbutton:hover {
  background-color: #E5007D;
  color: #ffffff;
  font-weight: bold;
}

.haddingadmintext {
  text-align: center !important;
}

/* =========================================== signmark ================================ */
.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  background: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill 0.6s ease-in-out 0.6s forwards,
    scale 0.3s ease-in-out 0.9s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}

.syncname {
  width: 100%;
  display: grid;
  justify-content: end;
  padding: 1% 2%;
}

.syncbutton {
  border: none;
  background: #C2C2C2;
  color: #757575;
  font-size: 15px;
  width: 125px;
  /* height: 32px; */
  height: auto;
  border-radius: 8px;
  font-weight: bold !important;
}

.syncbutton:hover {
  background: #E5007D;
  color: #fff;
}

.selecttourbutton {
  padding: 10px 22px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}



.selecttourbutton:hover {
  background: #E5007D;
  color: #fff;
}

.sorce-tour-btn {
  padding: 2px 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
}

.sorce-tour-btn:hover {
  background: #E5007D;
  color: #fff;
}

.refresh-btn {
  padding: 10px 22px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.refresh-btn:hover {

  background: #E5007D;
  color: #fff;
}

.refresh-btn-disabled {
  padding: 10px 22px;
  border: none;
  border-radius: 5px;
  cursor: no-drop;
  pointer-events: none;
  display: none;
}

.disableselectbtn {
  cursor: no-drop;
  padding: 10px 22px;
  border: none;
  border-radius: 5px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  /*     stroke: #4bb71b; */
  fill: #16ad59;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}


/* --------------------------------------------------filter button------------------------------------------ */
.filterbutton {
  width: 37px;
  height: 42px;
  border-radius: 0px 8px 8px 0px;
  border-left: none;
  border-bottom: 1px solid #e5e6eb;
  border-top: 1px solid #e5e6eb;
  border-right: 1px solid #e5e6eb;
  background: #f9fafb;
}

.TermsandConditions {
  display: flex !important;
  margin-top: 19px !important;
  cursor: pointer;
  padding: 0.2em;
  font-family: "GalanoGrotesque";
  color: #757575;
  font-size: 14px;
  width: 100%;
}

.btnrg {
  width: 100%;
  margin-top: 10% !important;
  padding: 0%;
}

.errors {
  text-align: left;
  padding: 1%;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}

.mobile-hadding {
  width: 100%;
  display: flex;
  display: none;
}

.hed-col {
  width: 100%;
  display: flex;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  border-bottom: 1px solid #c2c2c2;
}

/* ========================= input eye ============================================== */
.bi-eye {
  position: absolute;
  top: 40px;
  right: 10px;
}

/* /* ================================ EnterCompetition================================ */
.mainhadding {
  font-family: "GalanoGrotesque";
  width: 100%;
  display: flex;
  margin: auto;
  height: 50px;
  align-items: center;
  padding: 2%;
}

.loader-containerss {
  width: 80%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgb(255, 255, 255);
  z-index: 1;

}

.Entercontener {
  width: 50%;
  height: 100vh;
  margin: auto;
  background-color: #f8f8f8;
}

.Enterhadding {
  font-family: "GalanoGrotesque";
  width: 100%;
  height: 50px;
  display: grid;
  padding: 0 1%;
  align-content: space-around;
  font-size: 16px;
  color: #757575;
}

.enterpasscode {
  width: 100%;
  height: 55px;
  display: grid;
  align-items: center;
  padding: 2%;
  background: #e9fff3;
  margin: 10px auto;
  color: #16ad59;
}

.Competition {
  font-family: "GalanoGrotesque";
  font-size: 24px;
  color: #251f2b;
  font-weight: bold;
  width: 93%;
  text-align: center;
}

.Enterrow {
  display: flex;
  justify-content: space-between;
  height: 81px;
  margin: 2% auto !important;
  background: #fff;
  width: 98%;
  margin: auto;
  border-radius: 10px;
  align-items: center;
  padding: 2%;
}

.boxhadding {
  font-size: 15px;
  font-family: "GalanoGrotesque";
  color: #251f2b;
  line-height: 150%;
}

.boxdetiles {
  font-family: "GalanoGrotesque";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  width: 231px;
  height: 31px;
  color: #757575;
}

.btnentercom {
  width: 100%;
  margin-top: 7%;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #e5007d;
  content: attr(data-on);
}

input:focus+.slider {
  box-shadow: 0 0 1px #e5007d;
}

input:checked+.slider:before {
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  width: 40px;
  height: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

.btn-section .procced-btn {
  width: 60%;
  border: none;
  box-shadow: 0px 3px 5px -2px rgba(192, 31, 119, 0.3);
  border-radius: 6px;
  height: 56px;
  background: #e5007d;
}

.btn-section .roaster-disabled {
  width: 60%;
  border: none;
  box-shadow: 0px 3px 5px -2px rgba(192, 31, 119, 0.3);
  border-radius: 6px;
  height: 46px;
  color: rgb(210, 200, 200);
  font-size: 15px;
  background: #a7a0a5;
}

.btn-center {
  display: grid !important;
  justify-items: center;
}

/* ======================================= profile screen ==================== */
.box-end {
  transform: translate(-123px, 46px) !important;
}

.profilecon {
  width: 50%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.51);
  position: absolute;
  top: 0%;
  left: 25%;
}

.profilecontent {
  width: 60%;
  height: 100vh;
  margin: auto;
  /* padding: 2%; */
  display: grid;
  justify-items: stretch;
  align-items: center;
}

.profileboxd {
  width: 100%;
  height: 180px;
  background: #fff;
  padding: 2%;
  border-radius: 10px;
  display: grid;
  align-items: center;
  justify-items: center;
}

.profilebox {
  width: 100%;
  height: auto;
  background: #fff;
  padding: 2%;
  border-radius: 10px;
  display: grid;
  align-items: center;
  justify-items: center;
}

.profile-style {
  width: 100px;
  height: 100px;
  border-radius: 68px;
  background-color: aqua;
  display: grid;
  align-items: center;
  justify-items: center;
}

.imagesprview {
  width: 120px;
  height: 120px;
  border-radius: 68px;
  color: white;
  padding: 0%;
  background-color: #e5007d;
  text-align: center;
  display: grid;
  font-size: 50px;
  align-items: center;
}

.profilepictext {
  width: 40px;
  height: 40px;
  background-color: #e5007d;
  display: grid;
  justify-items: center;
  align-items: center;
  text-decoration: none !important;
  border-radius: 50px;
  font-size: 22px;

}


.EditBoxleague {
  width: 64% !important;
  position: fixed !important;
  left: 18% !important;
  bottom: 0%;
  display: grid;
  background: #ffffff;
  padding: 2%;
  border-radius: 10px;
}

/* --------------------------------------- players list----------------------------------------- */
.playesteam {
  width: 15%;
  color: #757575;
}

.tablerowcontent {
  display: flex !important;
  align-items: center !important;
}

/* =========================================MGMT screen====================================== */
.Mgmthadding {
  width: 100%;
  display: flex;
  height: 40px;
  border-top: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  align-items: center;
}

.rowhaddingbar {
  display: flex;
  padding: 0 1%;
}

.haddinsmgmyssc {
  display: flex !important;
  max-width: 768px !important;
  margin: auto !important;
  padding: 0px 2% !important;
  align-items: center;

}

.mainhaddings {
  width: 92%;
  text-align: center;
  font-size: 25px;
}

.selectteamname {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.playernamebox {
  width: 53%;

}

.emailidbox {
  color: #000000 !important;
  font-weight: 100 !important;
}

.mgmtplace {
  width: 67px;
  color: #757575;
}

.mgmtteam {
  width: 10%;
  color: #757575;
}

.mgmtpoint {
  width: 81%;
  text-align: right;
  color: #757575;
}

.MGMTRow {
  height: 40px;
  padding-top: 10px;
  border-bottom: 1px solid #c2c2c2;
}

.Mgmtplayers {
  display: flex;
  width: 100%;
  color: black;
}

.neareleaguecon {
  display: flex;
  height: 50px;
  align-items: center;
  margin-bottom: 8px;
  max-width: 768px;
  margin: auto;
}

/* ======================================successfully screen================================ */
.imagesrow {
  width: 100%;
  display: flex;
  height: 69px;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
  border-bottom: 1px solid #c2c2c2;
}

.d-box4 {
  width: 40%;
  height: 80vh;
  margin: auto;
  display: grid;
  justify-items: center;
  align-content: center;
}

.Suctage {
  font-size: 18px;
  font-weight: bold;
  color: #757575;
  cursor: pointer;
  height: 100px;
  display: grid;
  align-content: space-around;
  text-decoration: none;
}

.Sucptage {
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.spinnerssun {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 3px solid;
  border-color: #e5007d transparent #e5007d transparent;
  border-radius: 50%;
  animation: spin-anim 1s linear infinite;
}

.spoilorloder {
  margin: auto;
  display: grid;
  width: 100%;
  justify-items: center;
  height: 10vh;
}

.tegimages {
  width: 250px;
  height: 35px;
}

/* ============================================landing screen================================= */
.landingRow {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  background-color: #fff;
  margin: auto;
}

.haddingspoint {
  font-size: 11px;
  color: #757575;
  width: 80px;
  text-align: right;
  padding-right: 1%;

}

.photoimagelanding {
  width: 160px;
  height: 25px;
}

.target {
  font-size: 15px !important;
  color: #757575;
}

.target:hover {
  color: #FFFFFF;
}

.border-btm {
  border-top: 1px solid #c2c2c25e;
}

.dropdown-item:hover {
  background-color: #E5007D !important;
  color: #FFFFFF !important;
}

.contenorlanding {
  width: 100%;
  height: 100% !important;
}

.landing-box {
  width: 100%;
  background: #fff;
  border-radius: 6px;
  margin: 10px auto !important;
}

.nevbarbutton {
  width: 100%;
  text-align: right;
  padding: 0 2%;
}

.nevbarlogbutton {
  margin: 0 4%;
  border: none;
  background: #C2C2C2;
  color: #757575;
  font-size: 15px;
  width: 125px;
  height: 32px;
  border-radius: 8px;
  font-weight: bold !important;
}

.nevbarlogbutton:hover {
  background: #E5007D;
  color: #FFFFFF;
}

@keyframes LeftToright {
  0% {
    transform: translateX(-100px);
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
  }

  100% {
    transform: translateX(300px);
  }
}


.landingbuttonlink {
  font-family: "GalanoGrotesque";
  font-style: normal;
  height: 50px;
  font-size: 17px;
  line-height: 24px;
  color: #e5007d;
  text-decoration: none;
  width: 100%;
  font-weight: 600;
  display: grid;
  justify-items: center;
  border-radius: 10px;
}

.landinghadding {
  font-family: "GalanoGrotesque";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  width: 50%;
  margin: auto;
}

.landing-footer {
  width: 95%;
  height: 146px;
  margin: auto;
  background-color: #fff;
  display: grid;
  align-content: space-between;
  justify-items: center;
  border-radius: 10px;
}

.ladingtag {
  padding-left: 20px;
  padding-bottom: 10px;
  font-family: "GalanoGrotesque";
  font-weight: 600;
  font-size: 20px;
}

.fthed {
  height: 50px;
  width: 100%;
  padding: 2%;
  display: grid;
  align-items: center;
  font-family: "GalanoGrotesque";
  font-weight: 400;
  font-size: 18px;
  color: rgba(37, 31, 43, 1);
}

.landingbuttonlink {
  display: grid;
  align-content: space-around;
  border: none;
  background-color: white;
}

.bottembuttonrow {
  font-family: "GalanoGrotesque";
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 50px;
  margin: 20px 0px;
  padding: 0 2%;
}

.landingBTN {
  width: 22%;
  border: none !important;
  background: #fff;
  color: #e5007d;
  font-weight: 600;
}

.landingBTN:hover {
  background: #e5007d;
  color: #fff;
  border-radius: 10px;

}

/* ========================================= Profile Pic  ============================== */


.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input+label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #FFFFFF;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input+label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input+label:after {
  content: "\f040";
  font-family: 'FontAwesome';
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #F8F8F8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-preview>div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


.nevstyless {
  font-size: 30px !important;
  cursor: pointer;
  color: #000000;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;

  background-color: whitesmoke;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.contentrow {
  display: flex;
}

.nevcolumn {
  width: 15%;
}

.nevlogo {
  height: 70px;
  display: grid;
  justify-items: center;
  align-items: center;
}

.nevlistst {
  list-style: none;
  padding: 6% 10%;

}

.screensection {
  /* height: 100vh !important; */
  padding: 0% 1%;
}

.listnevbars {
  height: 41px;
  cursor: pointer;
  display: grid;
  align-items: center;

}


/* / ---------------------------------------------------MyTeamButtonTabs---------------------------------------------------------- / */


.contenthadding {
  font-size: 20px;
  font-weight: 600;
  font-family: "GalanoGrotesque";
  color: rgba(37, 31, 43, 1);
}

.teamnameonbord {
  font-size: 13px;
  color: #e5007d;
  text-align: right;
  font-weight: 400;
  padding-right: 5% !important;
}

.button-label button {
  border-radius: 8.91px;
}

.button-label::after {
  content: "";
  border-right: 1px solid #d5d3d3;
}

.Leaderboards-button {
  width: 195px;
  height: 28px;
  border: none;
  padding: 0px;
  background-color: #e5e5e5;
  color: #000000 !important;
  border-radius: 8.91px;
  font-size: 13px;
  font-weight: 600;
}

.all-btn {
  height: 46px;
  width: 600px;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  border-radius: 8.91px;
}

.activebutton {
  width: 196.33px;
  height: 39px;
  border: none;
  padding: 0px;
  background-color: white;
  color: #000000 !important;
  transition: 1s;
  border-radius: 8.91px;
  font-size: 14px;
  font-weight: 600;
  margin: 0px 2px;
}



.btn-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.gray-background {
  background-color: #e5e5e5;
}

/* ================================================= Edit League screen================================== */
.edithad {
  font-weight: 600;
  font-size: 20px;
  width: 100%;
  text-align: left;
  padding: 0 1%;
}

.rowscontents {
  max-width: 85% !important;
}

.btnentercomp {
  text-align: center;
  width: 50%;
  margin: auto;
  position: absolute;
  bottom: 3%;


}

.leaguerow {
  width: auto;
  display: flex;
  align-items: center;
  background: #e5e5e5;
  padding: 5px 0%;
  height: auto;
  border-radius: 10px;
}

.buttonsui {
  height: 38px;
  display: grid;
  align-content: space-evenly;
  align-items: start;
  margin: 0px;
  padding: 2px;

}

.buttonli {
  list-style: none;
  height: 51px;
  display: grid;
  align-items: center;
}


.EditBox {
  width: 38%;
  position: absolute;
  bottom: 0%;
  left: 30.5%;
  display: grid;
  background: #ffffff;
  padding: 2%;
  padding-top: 0px;
  border-radius: 10px;
  position: fixed;
}

.editmoon {
  font-family: "GalanoGrotesque";
  font-weight: 400;
  line-height: 150%;
  font-size: 15px;
  padding: 4px 0px;
  color: #251F2B;
}

.editupdate {
  font-family: "GalanoGrotesque";
  font-weight: 400;
  position: absolute;
  line-height: 150%;
  font-size: 15px;
  color: #757575;
}

/* ===============================================EditConfomscreen============================== */
.EditconfoBox {
  width: 30%;
  margin: auto;
  position: fixed;
  text-align: center;
  top: 35%;
  left: 35%;
  height: 200px;
  background: white;
  border-radius: 10px;
}

.EditfilterconfoBox {
  width: 30%;
  margin: auto;
  position: fixed;
  text-align: center;
  top: 35%;
  left: 35%;
  height: 242px;
  background: white;
  border-radius: 10px;
}

.editlinksbox {
  margin-top: 56px;
  display: flex;
  justify-content: space-around;
  height: 50px;
  align-items: center;
}

.editlinks {
  font-family: "GalanoGrotesque";
  text-decoration: none;
  font-size: 14px;
  color: #757575;
  border: none;
  background-color: transparent;
}

.editlinks:hover {
  color: #e5007d;
}

.edithade {
  font-family: "GalanoGrotesque";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
}

.Changingwill {
  font-family: "GalanoGrotesque";
  font-style: normal;
  font-size: 16px;
  line-height: 150%;
}

.editlinksbox {
  margin-top: 56px;
  display: flex;
  justify-content: space-around;
  height: 50px;
  align-items: center;
  border-top: 1px solid #c2c2c2;
}

.editcontent {
  position: absolute;
  background: rgba(0, 0, 0, 0.51);
  width: 100%;
  top: 0%;
  height: 100vh;
}

.editbutton {
  width: 50%;
  border: none;
  background: transparent;
  border-left: 1px solid #c2c2c2;
  height: 49px;
  color: #757575;
}

.buttonscontents {
  width: 100%;
  border-top: 1px solid #c2c2c2;
  position: absolute;
  bottom: 0%;
}

.editbutton:hover {
  color: #E5007D;
}

.editcontenthad {
  height: 94px;
  display: grid;
  align-items: end;
  padding: 2%;
}

/* ===================================nervescreen================================== */
.nervesrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 61px;
  background-color: rgb(194 194 194 / 17%);
}

.nervesTotal {
  font-family: "GalanoGrotesque";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
}

.time-part {
  font-family: "GalanoGrotesque";
  font-size: 14px !important;
  font-weight: 400;
  color: #251F2B;
  word-spacing: 2px;
}

.fa-exclamation-triangle {
  color: rgba(250, 173, 20, 255);
}

.nervesnum {
  font-family: "GalanoGrotesque";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #e5007d;
  padding-right: 13px;
}

.nervesNae {
  font-size: 11px;
  font-weight: 400;
  font-family: "GalanoGrotesque";
  color: #757575;
}

.neralartrow {
  width: 98%;
  margin: auto;
  text-align: center;
  height: 50px;
  display: grid;
  align-items: center;
  background: #fffbe6;
  border: 1px solid yellow;
  border-radius: 10px;
  margin-bottom: 29px;
}

/* ========================================joinleague====================== */
.joined {
  width: 100%;
  height: 50px;
  background: #e9fff3;
  display: grid;
  align-items: center;
  margin: 2% 0px;
  padding: 1%;
  color: #16ad59;
}

.jionicon {
  font-size: 20px !important;
}

.JoinedLeague {
  display: flex !important;
  width: 100%;
  height: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #16AD59;
  background-color: rgb(22 173 89 / 16%);
  box-shadow: 0px 2px 4px -2px rgb(22 173 89 / 12%);
  height: 40px;
  display: grid;
  align-items: center;
  padding: 1%;
  margin: 20px 0px;
  border-radius: 6px;
}

/* ================================== Editimage============================== */
.iconstyle {
  background: transparent !important;
  border: none !important;

}


.closebutton {
  text-align: right;
  font-size: 30px;
  cursor: pointer;
  text-decoration: none;
  color: black !important;
}

.closbuttonrow {
  width: 100%;
  text-align: end;
  height: 10px;
  margin-bottom: 27px;
}

.crosebtn {
  width: 100%;
  text-align: right;
}

.crosefilterbtn {
  width: 97%;
  text-align: right;
}

.Terms-conditions {
  display: none;
}

.forgot-pass {
  width: 100%;
}

/* =======================================team select screen================== */
.teamhadding {
  width: 100%;
  font-size: 28px;
  font-weight: 600;
  margin-top: 30px;
}

.multi-range-slider {
  box-shadow: none !important;
  padding: 30px 20px !important;
}


.multi-range-slider .thumb::before {
  box-shadow: none !important;
}

.multi-range-slider .bar-left {
  box-shadow: none !important;
  background: gainsboro !important;
}

.multi-range-slider .bar-right {
  box-shadow: none !important;
  background: gainsboro !important;
}

.flex-grow-1{
  flex-grow: 1 !important;
}

/* ======================================== Media query =================================== */
@media screen and (max-width: 1200px) {
  .mobile-hadding {
    width: 100%;
    display: flex;
  }

  .hed-col {
    width: 100%;
    display: flex;
    height: 70px;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
    border-bottom: 1px solid #c2c2c2;
  }
}

@media screen and (min-width: 1402px) {

  .sectionlanding {
    width: 100% !important;
    height: auto !important;
  }
}

@media screen and (max-width: 1400px) {
  .d-box4 {
    width: 57%;
    height: 80vh;
    margin: auto;
    display: grid;
    justify-items: center;
    align-content: center;
  }

  .btnrg {
    width: 100%;
    margin-top: 5% !important;
    padding: 0%;
  }

  p {
    padding-bottom: 5%;
  }

  .profilecontent {
    width: 65%;
    height: 100vh;
    margin: auto;
    display: grid;
    justify-items: stretch;
    align-items: center;
  }

  .EditBox {
    width: 50%;
    position: absolute;
    bottom: 0%;
    left: 25%;
    display: grid;
    background: #ffffff;
    padding: 2%;
    padding-top: 0px;
    border-radius: 10px;
    position: fixed;
  }

  .EditBoxleague {
    width: 100%;
    position: fixed;
    left: 0%;
    bottom: 0%;
    display: grid;
    background: #ffffff;
    padding: 2%;
    border-radius: 10px;
  }

  .all-btn {
    height: 46px;
    width: 500px;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    border-radius: 8.91px;
  }

  .Leaderboards-button {
    width: 165px;
    height: 28px;
    border: none;
    color: #000000 !important;
    padding: 0px;
    background-color: #e5e5e5;
    border-radius: 8.91px;
    font-size: 13px;
    font-weight: 600;
  }

  .activebutton {
    width: 160px;
    height: 39px;
    border: none;
    padding: 0px;
    margin: 0 4px;
    color: #000000 !important;
    background-color: white;
    transition: 1s;
    border-radius: 8.91px;
    font-size: 14px;
    font-weight: 600;
  }

  .d-box {
    width: 59% !important;
    height: 100%;
    margin: auto;
    position: relative;
  }

  .sectionlanding {
    width: 100% !important;
    height: auto !important;
  }

  .hading {
    font-weight: 700;
    font-size: 1.75rem !important;
    width: 100%;
    word-spacing: 6px;
    height: 100px;
    margin: auto;
    display: grid;
    align-items: center;
  }

  .welhading {
    font-weight: 700;
    font-size: 1.75rem !important;
    width: 100%;
    word-spacing: 6px;
    height: 100px;
    margin: auto;
    display: grid;
    align-items: center;
  }

  .rghading {
    font-weight: 700;
    font-size: 1.75rem !important;
    width: 100%;
    word-spacing: 6px;
    height: auto;
    margin: auto;
    display: grid;
    align-items: center;
  }
}

@media screen and (max-width: 1200px) {
  .mainimg {
    display: none;
  }

  .nevlistst {
    list-style: none;
    padding: 4% 13%;
  }

  .col1 {
    display: none;
  }

  .nevcolumn {
    width: 15% !important;
    /* padding: 0%; */
  }

  .hading {
    font-weight: 700;
    font-size: 1.75rem !important;
    width: 98%;
    word-spacing: 6px;
    height: 100px;
    margin: auto;
    display: grid;
    align-items: center;
  }

  .EditBoxleague {
    width: 100% !important;
    position: fixed;
    left: 0% !important;
    bottom: 0% !important;
    display: grid;
    background: #ffffff;
    padding: 2%;
    border-radius: 10px;
  }

  .welhading {
    font-weight: 700;
    font-size: 1.75rem !important;
    width: 98%;
    word-spacing: 6px;
    height: 100px;
    margin: auto;
    display: grid;
    align-items: center;
  }

  .d-box {
    width: 100%;
    height: 100vh;
    margin: auto;
    /* justify-content: center; */
  }

  .col {
    width: 100%;
    height: 100%;
    background-color: #fff;
    text-align: center;
    display: block;
  }

  .Competition {
    padding-left: 0px;
  }
}

@media screen and (min-width: 1030px) and (max-width: 1450px) {
  .sectionlanding {
    width: 100% !important;
    height: auto !important;
    background: "#F8F8F8";
  }

  .rowscontents {
    max-width: 81% !important;
  }

  .nevlistst {
    list-style: none;
    padding: 6% 20%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

  .Terms-conditions {
    display: block !important;
    margin-top: 130px;
    color: rgba(117, 117, 117, 1);
  }

  .form-outline {
    width: 82%;
  }

  .nevlogo {

    height: 70px;
    display: grid;
    align-items: center;
    padding: 0px 20px;

  }

  .nevbarbutton {
    width: 30%;
    /* text-align: right; */
    padding: 0 2%;
    position: absolute;
    right: 2%;
  }

  .screensection {
    /* height: 100vh !important; */
    padding: 0% 1%;
    width: 100% !important;
  }

  .listnevbars {
    height: 41px;
    cursor: pointer;
    display: grid;
    align-items: center;
    margin: 0px 12px;
  }

  .nevlistst {
    list-style: none;
    padding: 2% 4%;
    display: flex;
    justify-content: space-between;
  }

  .nevlogo {
    height: 70px;
    display: grid;
    align-items: center;
  }

  .nevcolumn {
    display: flex !important;
    width: 100% !important;
  }

  .contentrow {
    display: block;
  }

  .nevcolumn {
    display: block;
  }

  .adminbutton {
    width: 145px !important;
    height: 40px;

    right: 2%;
    border: none;
    border-radius: 8px;
    background-color: #C2C2C2;
    color: #757575;
    font-weight: bold;
  }

  .imagesrow {
    width: 100%;
    display: flex;
    height: 69px;
    align-items: center;
    justify-content: space-between;
    padding: 2%;
    margin-top: 20px;
    border-bottom: 1px solid #c2c2c2;
  }

  .teamhadding {
    width: 100%;
    font-size: 28px;
    font-weight: 600;
    margin-top: 14px;
  }

  .rowscontents {
    max-width: 80% !important;
  }

  .btnentercomp {
    text-align: center;
    width: 98%;
    margin: auto;
    position: absolute;
    bottom: 3%;
    left: 1%;
  }

  .spoilorloder {
    margin: auto !important;
    display: grid !important;
    width: 100% !important;
    justify-items: center !important;
    height: 10vh !important;
    position: relative;
    align-items: center;

  }


  .mainhadding {
    font-family: "GalanoGrotesque";
    width: 100%;
    display: flex;
    margin: auto;
    height: 70px;
    align-items: center;
    padding: 2%;
  }

  .sectionlanding {
    width: 100% !important;
    height: auto !important;
  }

  .profilecon {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.51);
    position: absolute;
    top: 0%;
    left: 0%;
  }

  .teamselectioncon {
    margin-top: 20px;
  }

  .profilebox {
    width: 100%;
    height: auto;
    background: #fff;
    padding: 2%;
    border-radius: 10px;
    display: grid;
    align-items: center;
    justify-items: center;
  }

  .EditBoxleague {
    width: 100% !important;
    position: fixed;
    left: 0% !important;
    bottom: 0% !important;
    display: grid;
    background: #ffffff;
    padding: 2%;
    border-radius: 10px;
  }

  .profilecontent {
    width: 67%;
    height: 100vh;
    margin: auto;
    padding: 2%;
    display: grid;
    justify-items: stretch;
    align-items: center;
  }

  .Leaderboards-button {
    width: 195px;
    height: 28px;
    border: none;
    padding: 0px;
    color: #000000 !important;
    background-color: #e5e5e5;
    border-radius: 8.91px;
    font-size: 13px;
    font-weight: 600;
  }

  .all-btn {
    height: 46px;
    width: 600px;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    border-radius: 8.91px;
  }

  .activebutton {
    width: 189.33px;
    height: 39px;
    border: none;
    padding: 0px;
    color: #000000 !important;
    background-color: white;
    transition: 1s;
    border-radius: 8.91px;
    font-size: 14px;
    font-weight: 600;
  }

  .mainimg {
    display: none;
  }

  .col1 {
    display: none;
  }

  .logo {
    width: 211;
    height: 140;
    padding-bottom: 10%;
  }

  .hading {
    font-weight: 700;
    font-size: 1.75rem !important;
    width: 100%;
    word-spacing: 6px;
    height: 100px;
    margin: auto;
    display: grid;
    align-items: center;
  }

  .welhading {
    font-weight: 700;
    font-size: 1.75rem !important;
    width: 100%;
    word-spacing: 6px;
    height: 100px;
    margin: auto;
    display: grid;
    align-items: center;
  }

  .EditconfoBox {
    width: 51%;
    margin: auto;
    position: fixed;
    text-align: center;
    top: 39%;
    left: 25%;
    height: 200px;
    background: white;
    border-radius: 10px;
  }

  .EditfilterconfoBox {
    width: 51%;
    margin: auto;
    position: fixed;
    text-align: center;
    top: 39%;
    left: 25%;
    height: 242px;
    background: white;
    border-radius: 10px;
  }

  .contenor {
    width: 100%;
    height: 100vh !important;
  }

  p {
    font-family: "GalanoGrotesque";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem !important;
    line-height: 150%;
    width: 327px;
    text-align: center;
    color: #757575;
    margin: auto;
    padding-bottom: 8% !important;
  }

  .d-box {
    width: 100%;
    height: 100vh;
    margin: auto !important;
    /* position: absolute; */
  }

  .d-box1 {
    width: 100%;
    height: 100%;
    margin: 15% auto;
    display: grid;
    justify-items: center;
    align-content: center;
  }

  .Entercontener {
    width: 100%;
    height: 100vh;
    margin: auto;
    background-color: #f8f8f8;
  }

  .col {
    width: 100%;
    height: 100%;
    background-color: #fff;
    text-align: center;
    display: block;
  }

  .btn2 {
    width: 100%;
    margin-top: 38% !important;
    padding: 0%;
  }

  .btnrg {
    width: 100%;
    margin-top: 38% !important;
    padding: 0%;
  }

  .Competition {
    padding-left: 0px;
  }

  .landinghadding {
    font-family: "GalanoGrotesque";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    width: 98%;
    margin: auto;
  }

  .landing-box {
    width: 100%;
    margin: 14px auto !important;
    background: #fff;
    border-radius: 6px;
  }

  .landingRow {
    width: 100%;
    height: 66px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
    background-color: #fff;
    margin-top: -64px;
  }

  .EditBox {
    width: 96%;
    position: absolute;
    bottom: 2%;
    left: 2%;
    display: grid;
    background: #ffffff;
    padding: 2%;
    border-radius: 10px;

  }

  .box-end {
    transform: translate(0px, 46px) !important;
  }
}

@media screen and (min-width: 330px) and (max-width: 767px) {

  /* @media screen and (min-width: 250px) and (max-width: 767px) {  full responsive query*/
  .alert-triangle {
    position: relative;
    top: 31px;
    right: 170px !important;
  }

  .haddingspoint {
    font-size: 11px;
    color: #757575;
    text-align: right;
    padding-right: 1%;
    width: 100%;

  }

  .listnevbars {
    height: 41px;
    cursor: pointer;
    display: grid;
    align-items: center;
    margin: 0px 11px;
    font-size: 11px;
  }

  .nevlistst {
    list-style: none;
    padding: 4% 2%;
    display: flex;
  }

  .syncname {
    width: 50%;
    display: grid;
    justify-content: start;
    padding: 1% 2%;
    margin-top: -55px;
  }

  .photoimagelanding {
    width: 100px;
    height: 20px;
    margin: 0px 12px;
  }

  .contenthadding {
    font-size: 11px;
    font-weight: 600;
    font-family: "GalanoGrotesque";
    color: rgba(37, 31, 43, 1);
  }

  .adminbutton {
    width: 99px !important;
    height: 40px;
    /* position: absolute; */
    right: 2%;
    border: none;
    border-radius: 8px;
    background-color: #C2C2C2;
    color: #757575;
    font-weight: bold;
    font-size: 10px;
  }

  .form-outline {
    width: 96% !important;
  }

  .EditBoxleague {
    width: 100% !important;
    position: fixed !important;
    left: 0% !important;
    bottom: 0%;
    display: grid;
    background: #ffffff;
    padding: 2%;
    border-radius: 10px;
  }

  .forgot-pass {
    width: 119%;
  }

  .btn-section .procced-btn {
    width: 100%;
    border: none;
    box-shadow: 0px 3px 5px -2px rgba(192, 31, 119, 0.3);
    border-radius: 6px;
    height: 56px;
    background: #e5007d;
  }

  .Leaderboards-button {
    width: 110.33px;
    height: 28px;
    border: none;
    padding: 0px;
    color: #000000 !important;
    background-color: #e5e5e5;
    border-radius: 8.91px;
    font-size: 13px;
    font-weight: 600;
  }

  .all-btn {
    height: 32px;
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    border-radius: 8.91px;
  }

  .rowscontents {
    max-width: 98% !important;
  }

  .activebutton {
    width: 125px;

    border: none;
    padding: 0px;
    color: #000000 !important;
    background-color: white;
    transition: 1s;
    border-radius: 8.91px;
    font-size: 14px;
    font-weight: 600;
  }

  .errorshows {
    padding-bottom: 17%;
  }

  .mainimg {
    display: none;
  }

  .col1 {
    display: none;
  }

  .logo {
    width: 211px;
    height: 140px;
    padding-bottom: 0% !important;
  }

  .col-3 {
    flex: 0 0 auto;
    width: 2% !important;
  }

  .d-box {
    width: 95% !important;
    height: 100vh;
    position: relative;
  }

  .d-box1 {
    width: 100%;
    height: 100%;
    margin: 3% auto;
    display: grid;
    justify-items: center;
    align-content: center;
  }

  .d-box3 {
    width: 100%;
    height: 70vh;
  }

  .Suctage {
    font-size: 18px;
    font-weight: bold;
    color: #757575;
    height: 155px;
    cursor: pointer;
    display: grid;
    align-items: end;
    text-decoration: none;
  }

  .d-box4 {
    width: 98%;
    height: 80vh;
    margin: auto;
    display: grid;
    justify-items: center;
    align-content: center;
  }

  .col {
    width: 100%;
    height: 100%;
    background-color: #fff;
    text-align: center;
    display: block;
  }

  .button {
    width: 100%;
    height: 56px;
    background: #e5007d;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 5px -2px rgb(192 31 119 / 30%);
    border-radius: 6px;
    grid-gap: 8px;
    gap: 8px;
    color: #ffffff;
    font-size: 0.875rem !important;
    font-weight: 600;
    cursor: pointer;
    border-color: #ffffff;
    border: none;
  }

  .button2 {
    padding-top: 5%;
    width: 100%;
    padding: 2%;
  }

  .contenorlanding {
    width: 100%;
    height: auto !important;
  }

  p {
    font-family: "GalanoGrotesque";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem !important;
    line-height: 150%;
    width: 327px;
    height: 120px;
    text-align: center;
    color: #757575;
    margin: auto;
    padding: 4% 0%;
  }

  .logo1 {
    display: none;
  }

  .hading {
    font-weight: 700;
    font-size: 1.75rem !important;
    width: 100%;
    word-spacing: 6px;
    margin-top: 50px;
    display: grid;
    align-items: center;
  }

  .welhading {
    font-weight: 700;
    font-size: 1.75rem !important;
    width: 50%;
    word-spacing: 6px;
    margin: 0px auto;
    display: grid;
    align-items: center;
  }

  .rghading {
    font-weight: 700;
    font-size: 1.75rem !important;
    width: 100%;
    word-spacing: 6px;
    height: 5px;
    margin: 20px 0px;
    display: grid;
    align-items: center;
  }

  .Enterhadding {
    font-family: "GalanoGrotesque";
    width: 100%;
    height: 50px;
    display: grid;
    padding: 0px 1%;
    align-content: space-around;
    font-size: 16px;
    color: #757575;
  }

  .Entercontener {
    width: 100% !important;
    margin: auto;
    padding: 2%;
    background-color: #f8f8f8;
  }

  .btnentercom {
    width: 100%;
    margin-top: 12%;
  }

  .teblelname {
    text-align: center !important;
  }

  .Competition {
    padding-left: 0px;
  }

  .tegimages {
    width: 120px;
    height: 25px;
  }

  .landingRow {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
    background-color: #fff;
    margin-top: -18px;
  }

  .landing-box {
    width: 100%;
    /* hieght new added by hitesh */
    /* height: 100% !important;    */
    margin: 14px auto !important;
    background: #fff;
    border-radius: 6px;
    margin: auto;
  }

  .landinghadding {
    font-family: "GalanoGrotesque";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    width: 100%;
    margin: auto;
  }

  .editlinksbox {
    margin-top: 56px;
    display: flex;
    justify-content: space-around;
    height: 50px;
    align-items: center;
    border-top: 1px solid #c2c2c2;
  }

  .editcontent {
    position: absolute;
    background: rgba(0, 0, 0, 0.51);
    width: 100%;
    top: 0%;
    height: 100vh;
  }

  .EditconfoBox {
    width: 92%;
    margin: auto;
    position: fixed;
    text-align: center;
    top: 35%;
    left: 4%;
    height: 200px;
    background: white;
    border-radius: 10px;
  }

  .EditfilterconfoBox {
    width: 92%;
    margin: auto;
    position: fixed;
    text-align: center;
    top: 35%;
    left: 4%;
    height: 242px;
    background: white;
    border-radius: 10px;
  }

  .editbutton {
    width: 36%;
    border: none;
    background: transparent;
    border-left: 1px solid #c2c2c2;
    height: 49px;
  }

  .EditBox {
    width: 100%;
    position: fixed;
    left: 0%;
    bottom: 0%;
    display: grid;
    background: #ffffff;
    padding: 2%;
    border-radius: 10px;
  }

  .bottembuttonrow {
    font-family: "GalanoGrotesque";
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 50px;
    margin: 10px 0px;
    padding: 0 2%;
  }

  .EditBoxleague {
    width: 100%;
    position: fixed;
    left: 0%;
    bottom: 0%;
    display: grid;
    background: #ffffff;
    padding: 2%;
    border-radius: 10px;
  }

  .MGMTRow {
    height: 40px;
    padding-top: 10px;
    border-bottom: 1px solid #c2c2c2;
  }

  .btn2 {
    width: 100%;
    margin-top: 20% !important;
    padding: 0%;
  }

  .btnrg {
    width: 100%;
    margin-top: 6% !important;
    padding: 0%;
  }

  .homebuton {
    background-color: transparent !important;
    color: #e5007d !important;
    border: 1px solid !important;
  }


  .sectionlanding {
    width: 100% !important;
    height: auto !important;
    background: "#F8F8F8";
  }

  .teamhadding {
    width: 100%;
    font-size: 28px;
    font-weight: 600;
    margin-top: 6px;
  }

  .fthed {
    height: 65px;
    width: 100%;
    text-align: center;
    display: grid;
    align-items: center;
    text-align: inherit;
    padding: 4%;
    margin-bottom: 10px;
  }

  .photoimagelanding {
    width: 100px;
    height: 20px;
  }

  .nevbarbutton {
    width: 100%;
    text-align: right;
    padding: 0 2%;
    display: flex;
    justify-content: flex-end;
  }

  .profilecontent {
    width: 100%;
    height: 100vh;
    margin: auto;

    display: grid;
    justify-items: stretch;
    align-items: center;
  }

  .enterpasscode {
    width: 100%;
    height: 35px;
    display: grid;
    align-items: center;
    padding: 2%;
    background: #e9fff3;
    margin: 10px auto;
    color: #16ad59;
  }

  .nevbarlogbutton {
    margin: 0 4%;
    border: none;
    background: #C2C2C2;
    color: #757575;
    font-size: 11px;
    width: auto;
    padding: 0% 6%;
    height: 26px;
    border-radius: 8px;
    font-weight: bolder;
  }

  .landingBTN {
    width: 50%;
    font-size: 14px;
  }

  .top-bar .search-section {

    margin-bottom: 0px !important;
  }

  .inputssize {
    height: 30%;
  }

  .target {
    font-size: 11px !important;
    color: #757575;
  }

  .inputsspech {
    margin-top: 20px;
  }

  .neralartrow {
    width: 96% !important;
    margin: auto;
    text-align: left;
    height: 50px;
    display: grid;
    align-items: center;
    background: #fffbe6;
    border: 1px solid yellow;
    border-radius: 10px;
    margin-bottom: 29px;
    font-size: 15px;
  }

  .spoilorloder {
    margin: auto !important;
    display: grid !important;
    width: 100% !important;
    justify-items: center !important;
    height: 10vh !important;
    position: relative;
    align-items: center;

  }

  .btnentercomp {
    text-align: center;
    width: 98%;
    position: absolute;
    bottom: 3%;
    left: 1%;
  }

  .haddingadmintext {
    text-align: left !important;
    padding: 0% 4%;
  }

  .nevcolumn {
    display: flex !important;
    width: 100% !important;
  }

  .contentrow {
    display: block;
  }

  .screensection {
    /* height: 100vh !important; */
    padding: 0% 1%;
    width: 100% !important;
  }

  .nevbarbutton {
    width: 30%;
    /* text-align: right; */
    padding: 0 2%;
    position: absolute;
    right: 2%;
  }
}

@media screen and (min-width: 300px) and (max-width: 320px) {

  .ent-comp-btn {
    margin-top: 20% !important;
  }

  .target {
    font-size: 7px !important;
    color: #757575;
  }

  .haddingadmintext {
    text-align: left !important;
    padding: 0% 2%;
  }

  .top-bar .search-section input {
    height: 40px;
    outline: none;
    background: #f9fafb;
    width: 75% !important;
  }

  .haddingspoint {
    font-size: 11px;
    color: #757575;
    width: 100%;
    text-align: right;
    padding-right: 1%;
  }

  .EditBox {
    width: 97%;
    /* margin: auto !important; */
    position: absolute;
    bottom: 0%;
    position: fixed;
    left: 2%;
    display: grid;
    background: #ffffff;
    padding: 2%;
    border-radius: 10px;
    /* justify-content: space-evenly; */
  }

  .Leaderboards-button {
    width: 85px;
    height: 28px;
    color: #000000 !important;
    border: none;
    padding: 0px;
    background-color: #e5e5e5;
    border-radius: 8.91px;
    font-size: 10px;
    font-weight: 600;
  }

  .profilecontent {
    width: 100%;
    margin: auto;
    display: grid;
    justify-items: stretch;
    align-items: center;
  }

  .all-btn {
    height: 32px;
    width: 270px;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    border-radius: 8.91px;
  }

  .activebutton {
    width: 80px;
    height: 28px;
    color: #000000 !important;
    border: none;
    padding: 0px;
    background-color: white;
    transition: 1s;
    border-radius: 8.91px;
    font-size: 10px;
    font-weight: 600;
  }

  .top-bar .search-section {
    margin-top: 15px !important;
    margin-bottom: 0px !important;
  }

  .nevbarbutton {
    width: 100%;
    text-align: right;
    padding: 0 2%;
    display: flex;
    justify-content: flex-end;
  }

  .nevbarlogbutton {
    margin: 0 4%;
    border: none;
    background: #C2C2C2;
    color: #757575;
    font-size: 11px;
    width: auto;
    padding: 0% 2%;
    height: 26px;
    border-radius: 8px;
    font-weight: 500;
  }

  .photoimagelanding {
    width: 100px;
    height: 20px;
  }

  .EditconfoBox {
    width: 94% !important;
    position: fixed;
    top: 30% !important;
    margin: 0 3% !important;
    left: 0%;
    height: 200px;
    background: white;
    border-radius: 10px;
  }

  .EditfilterconfoBox {
    width: 94% !important;
    position: fixed;
    top: 30% !important;
    margin: 0 3% !important;
    left: 0%;
    height: 242px;
    background: white;
    border-radius: 10px;
  }

  .editcontent {
    position: absolute;
    background: rgba(0, 0, 0, 0.51);
    width: 100%;
    top: 0%;
    height: 100vh;
  }

  .mid-section {
    margin: 0.5rem 0 0 !important;
  }

  .forgot-pass {
    width: 119%;
  }

  .form-outline {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .sectionlanding {
    width: 100% !important;
    height: auto !important;
    background: "#F8F8F8";
  }

  .fthed {
    height: 60px;
    width: 100%;
    padding: 2%;
    display: grid;
    align-items: center;
    font-family: "GalanoGrotesque";
    font-weight: 400;
    font-size: 18px;
    color: rgba(37, 31, 43, 1);
    margin-bottom: 10px;
  }

  .alert-triangle {
    position: relative;
    top: 31px;
    right: 151px !important;
  }

  .landingBTN {
    width: 50%;
    font-size: 15px;
  }

  .neralartrow {
    width: 96% !important;
    margin: auto;
    text-align: center;
    height: 50px;
    display: grid;
    align-items: center;
    background: #fffbe6;
    border: 1px solid yellow;
    border-radius: 10px;
    margin-bottom: 29px;
    font-size: 15px;
  }

  .btn-section .procced-btn {
    width: 100%;
    border: none;
    box-shadow: 0px 3px 5px -2px rgba(192, 31, 119, 0.3);
    border-radius: 6px;
    height: 56px;
    background: #e5007d;
  }

  .mainimg {
    display: none;
  }

  .col1 {
    display: none;
  }

  .logo {
    width: 185px !important;
    height: 110px !important;
    padding: 0%;
  }

  .btnrg {
    width: 100%;
    margin-top: 3% !important;
    padding: 0%;
  }

  .inputssize {
    height: 30% !important;
  }

  .rghading {
    font-weight: 700;
    font-size: 1.75rem !important;
    width: 100%;
    word-spacing: 6px;
    height: 50px;
    margin: auto;
    display: grid;
    align-items: center;
  }

  .d-box {
    width: 95% !important;
    height: 100vh;
    margin: auto;
    position: relative;
  }

  p {
    margin: 43px 0px !important;
  }

  .d-box1 {
    width: 93%;
    height: 100%;
    margin: auto;
    position: absolute;
  }

  .col {
    width: 100%;
    height: 100%;
    background-color: #fff;
    text-align: center;
    display: block;
  }

  .hading {
    font-weight: 700;
    font-size: 1.3rem !important;
    width: 100%;
    word-spacing: 6px;
    height: 9px;
    margin: auto;
    display: grid;
    align-items: center;
  }

  .welhading {
    font-weight: 700;
    font-size: 1.3rem !important;
    width: 100%;
    word-spacing: 6px;
    height: 9px;
    margin: auto;
    display: grid;
    align-items: center;
  }

  .TermsandConditions {
    display: flex !important;
    margin-top: 19px !important;
    cursor: pointer;
    padding: 0.1em;
    font-family: "GalanoGrotesque";
    color: #757575;
    font-size: 14px;
    width: 106% !important;
  }

  .btn2 {
    width: 100% !important;
    margin-top: 0% !important;
    padding: 0% !important;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem !important;
    line-height: 144%;
    width: 98%;
    height: 114px;
    text-align: center;
    color: #757575;
    margin: auto;
    padding: 10% 0%;
  }

  .Entercontener {
    width: 100% !important;
    height: 100%;
    padding: 0px 2%;
    margin: auto;
    background-color: #f8f8f8;
  }

  .col-3 {
    flex: 0 0 auto;
    width: 25%;
    display: grid !important;
    align-content: space-around !important;
  }

  .Competition {
    padding-left: 0px;
  }

  .Enterhadding {
    font-family: "GalanoGrotesque";
    width: 100%;
    height: 50px;
    display: grid;
    padding: 0px 2%;
    align-content: space-around;
    font-size: 16px;
    color: #757575;
  }
}

@media screen and (max-height: 785px) {
  .btn2 {
    width: 100%;

    padding: 0%;
  }
}


@media only screen and (max-width: 1200px) {
  .left-img {
    display: none;
  }
}
.user-input-wrp {
	position: relative;
	width: 100%;
}
.user-input-wrp .inputText{
	width: 100%;
    outline: none;
    padding-left: 13px;
    height: 50px;
	padding-top: 10px;
}
.user-input-wrp .floating-label {
	position: absolute;
	pointer-events: none;
	top: 35px;
	left: 10px;
	transition: 0.2s ease all;
    color: #757575;
    
}
.user-input-wrp input:focus ~ .floating-label,
.user-input-wrp input:not(:focus):valid ~ .floating-label{
	top: 25px;
	left: 10px;
	font-size: 0.625rem !important;
	opacity: 1;
}
.user-input-wrp .floating-labels {
	position: absolute;
	pointer-events: none;
	top: 35px;
	left: 10px;
	transition: 0.2s ease all;
    color: #757575;
    
}
.user-input-wrp input:focus ~ .floating-labels,
.user-input-wrp input:not(:focus):valid ~ .floating-labels{
	top: 25px;
	left: 10px;
	font-size: 0.625rem !important;
	opacity: 1;
}
input{
    border: 1px solid #C2C2C2;
    border-radius: 6px;
}
.btn2{
    width: 100%;
    margin-top: 10%;
    padding: 0%;
}
.form-outline{
    width: 100%;
}



/* ========================== Registrationscreen  ============================ */
.tablerow {
  width: 100%;
}
.button-contenor{
  padding-top: 10%;
}

input[type=checkbox] + label {
  display: block;
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
  font-family: 'GalanoGrotesque';
  color: #757575;
  font-size: 14px;
}

input[type=checkbox] {
  display: none;
}

input[type=checkbox] + label:before {
  content: "\2714";
    border: 0.1em solid #C2C2C2;
    border-radius: 0.2em;
    display: inline-block;
    width: 24px;
    height: 24px;
    padding-left: -0.9em;
    padding-bottom: 0.3em;
    margin-right: 0.2em;
    vertical-align: bottom;
    color: transparent;
    transition: .2s;
    
}

input[type=checkbox] + label:active:before {
  transform: scale(0);
}

input[type=checkbox]:checked + label:before {
  background-color: #E5007D;
  border-color: #C2C2C2;
  color: #fff;
  
}

.tournament-list-admin-penal{
  color: black;
  text-decoration: none;
}

.tournament-list-admin-penal:hover{
  color: black;
}
@media screen and (min-width:280px) and (max-width:767px){
#res_stack{
  width: 50%;
}
}
